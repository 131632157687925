import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
const calendar = () => import('../views/calendar/list')
const calendar_addOrEdit= () => import('../views/calendar/addOrEdit')
const calendar_detail = () => import('../views/calendar/detail')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/calendar',
    children: [
      {
        path: '/calendar',
        name: 'calendar',
        component: calendar
      }
    ]
  },
  {
    path: '/calendar/addOrEdit',
    name: 'calendar',
    component: calendar_addOrEdit
  },
  {
    path: '/calendar/detail',
    name: 'calendar',
    component: calendar_detail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
