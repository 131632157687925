<template>
  <el-container>
    <el-header class="header">管理后台</el-header>
    <el-container class="container_content">
      <el-aside class="aside">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @select="handleOpen"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">藏品管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main class="main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    handleOpen(key) {
      if (key == 1) {
        this.$router.push({
          path: '/calendar'
        })
      }
      console.log(key, keyPath)
    }
  }
}
</script>

<style scoped lang="less">
  .el-menu-vertical-demo {
    width: 200px !important;
  }
  .header {
    height: 10vh !important;
    background-color: black !important;
    line-height: 10vh;
    font-weight: bold;
    font-size: 20px;
    color: #fff !important;
    letter-spacing: 10px;
    min-width: 1280px;
  }
  .container_content {
    height: 90vh; 
    min-width: 1280px;                    
  }
  .aside {
    width: 200px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .main {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  ::v-deep .el-header, ::v-deep .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
  }
  
  ::v-deep .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
  }
  
  ::v-deep .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
  }
</style>