import axios from 'axios'
// axios.defaults.withCredentials = true
let server = axios.create({
  baseURL: 'https://rjlz.ldbf.fun',
  headers: {
    'content-type': 'application/json'
  }
})

server.interceptors.request.use(
  config => {
    // const token = sessionStorage.getItem('token')
    // if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    //   config.headers.authorization = token  //请求头加上token
    // }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
  // http response 拦截器
server.interceptors.response.use(
  res => {
    if (res && res.status == 200) {
      return res.data
    } else {
      return res
    }
  },
  //接口错误状态处理，也就是说无响应时的处理
  error => {  
    console.log(error.message)
    return Promise.reject(error.message ? error : error.response.status) // 返回接口返回的错误信息
  }
)
export default server